import React, { ReactElement } from 'react'
import Link from 'next/link'
import { UiLayoutConstant } from '@/globals/uiLayoutConstant'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import { ErrorComponent } from '@/components/static/ErrorBoundary/ErrorComponent/ErrorComponent'

const Page = (): ReactElement => {
    return (
        <ErrorComponent
            title={<>Oops. 404. Page not found.</>}
            text={
                <>
                    Maybe this page moved? Got deleted? Is hiding out in
                    quarantine? Never existed in the first place? Let&apos;s
                    go&nbsp;
                    <Link
                        href={{ pathname: UiLayoutConstant.SPLASH_PAGE }}
                        className={reusableCssClass.clickableText}
                        onClick={() => {
                            setTimeout(() => {
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                window.location = window.location.origin
                            }, 0)
                        }}
                    >
                        home
                    </Link>{' '}
                    and try again from there.
                </>
            }
            sourceTextForErrorLog={'404 Page'}
        />
    )
}

Page.isStatic = true

export default Page
